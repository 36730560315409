import "../../../../stylesheets/platform";
import "../../../../stylesheets/templates/platform/template-0";
import "../../../../stylesheets/templates/platform/template-1";
import "../../../../stylesheets/templates/platform/template-2";
import "../../../../stylesheets/templates/platform/template-3";
import "../../../../stylesheets/templates/platform/template-4";
import "../../../../stylesheets/templates/platform/template-5";
import "../../../../stylesheets/accounts/base";
import "./index";
import "./greeting_cards";
import { formatPhoneNumber } from "../../../backoffice/utils";
import { pickTextColorBasedOnBgColorAdvanced } from "../../../utils";

$(document).ready(function () {
  // Project selection
  $(".project button").click(function (event) {
    const parent_id = $(this).parent().parent().attr("data-id");
    $(".project button").show();
    $(this).hide();
    console.log($(`#project-form-${parent_id}`));
    console.log($("#donation_form"));
    $(`#project-form-${parent_id}`).append($("#donation_form"));
    $(`#donator_donation_transactions_attributes_0_location_id`).val(parent_id);
  });

  // Project selection
  $(".project button").click(function (event) {
    const parent_id = $(this).parent().parent().attr("data-id");
    $(".project button").show();
    $(this).hide();
    console.log($(`#project-form-${parent_id}`));
    console.log($("#donation_form"));
    $(`#project-form-${parent_id}`).append($("#donation_form"));
    $(`#donator_donation_transactions_attributes_0_location_id`).val(parent_id);
  });

  $(".send-company-request").click(({ currentTarget }) => {
    $("#loader").show();
    var card_id = currentTarget.getAttribute("data-card-id");
    var form = $(`#new-company-form-${card_id}`);
    formatPhoneNumber();
    if (form[0].checkValidity() === true) {
      $.ajax({
        type: "POST",
        url: "/company_donator",
        data: form.serialize(),
      }).done(function (responseData) {
        $("#loader").hide();
        $(".company-card-form").removeClass("d-flex");
        $(".company-card-form").addClass("d-none");
        $(".success-message").removeClass("d-none");
      });
    } else {
      $("#loader").hide();
      alert("Los datos ingresados no están correctos");
    }
  });

  if ($("#certificate-form")) {
    let modal_primary_color = $("#certificate-form").attr("data-primary-color");
    let modal_secondary_color = $("#certificate-form").attr(
      "data-secondary-color"
    );
    if (modal_primary_color && modal_secondary_color) {
      let color = pickTextColorBasedOnBgColorAdvanced(
        modal_primary_color,
        modal_primary_color,
        modal_secondary_color
      );
      $("#certificate-form")[0].style.setProperty("--modal-color", color);
    }
    $("#certificate-form").modal("show");
  }

  $(".donation-type-select button").on("click", function () {
    const type = this.getAttribute("data-type");
    hideRecurringCheckbox(type);
  });

  $('[name="recurring_radio"]').on("change", function () {
    const type = this.value;
    hideRecurringCheckbox(type);
  });
});

const hideRecurringCheckbox = (type) => {
  if (type == "once" && $("#recurring-radio-container")[0]) {
    if (
      $("#recurring-radio-container")[0].dataset.activeSubscription == "true"
    ) {
      $("#recurring-radio-container").addClass("d-flex");
      $("#recurring-radio-container").removeClass("d-none");
    }
  } else if (type == "subscription" && $("#recurring-radio-container")[0]) {
    $("#recurring-radio-container").removeClass("d-flex");
    $("#recurring-radio-container").addClass("d-none");
  }
};
